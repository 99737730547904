<template>
  <Row class="px-1">
    <i-col span="24">
      <Table border ref="verdictTable" :columns="tColumns" :data="tData" class="mt-1"></Table>
    </i-col>
  </Row>
</template>

<script>
export default {
  name: "Verdict",
  data() {
    return {
      tColumns: [
        {
          title: "使用场景",
          key: "verdictDesc",
        },
        {
          title: "公共展示部分",
          key: "commonVerdictContent",
        },
        {
          title: "小程序展示部分",
          key: "mpVerdictContent",
        },
        {
          title: "管理端展示部分",
          key: "adminVerdictContent",
        },
      ],
      tData: [
        {
          verdictDesc: "分裂样因子异常",
          commonVerdictContent:
            "该儿童胆小，害怕上学以及某些动物或场景，容易做噩梦，有时还会出现其他异常行为或想法；还有可能看到实际不存在的事物，或者听到实际不存在的声音。",
          mpVerdictContent: "建议家长带该儿童到精神科门诊进一步诊断。",
          adminVerdictContent:
            "该因子不能作为精神障碍的直接依据，应由专业机构进一步诊断。",
        },
        {
          verdictDesc: "抑郁因子异常",
          commonVerdictContent:
            "该儿童存在抑郁倾向，常处于悲伤、忧虑、内疚、孤独的情绪中，容易激动和紧张，感觉自己没用，担心做错事，担心自己不被他人喜欢；也有可能对他人不信任，不合群。抑郁的原因是多方面的，然而儿童抑郁家庭因素是主要方面，比如父母关系不和，养育方式存在较多问题。",
          mpVerdictContent:
            "家长应首先关注家庭环境、夫妻关系；其次加强与儿童的交流，尤其是情感上的交流，了解儿童的情感、想法，也要真切的表达自己的想法，使感情上更亲密；另外家庭和老师要鼓励儿童参加集体活动，鼓励儿童与同学、同伴交往。如果长期处于高抑郁状态，应当寻求专业帮助。",
          adminVerdictContent:
            "建议关注家庭因素，如婚姻关系、养育方式，还需要进一步了解该儿童在学校的情况。",
        },
      ],
      theight: window.innerHeight - 175,
    };
  },
  mounted() {
    window.addEventListener(
      "resize",
      () => (this.theight = window.innerHeight - 175),
      false
    );
  },
  methods: {},
};
</script>

<style>
</style>
